<template>
  <div class="query-rule">
    <div>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            label="Reegel"
            v-model="rule.rule"
            outlined
            hide-details
            :items="rule_options"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            outlined
            v-model="rule.operator"
            hide-details
            :items="['equal', 'not equal', 'in', 'not in', 'less than', 'greater than', 'empty', 'not empty']"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-if="rule.rule == 'insurer'"
            label="Selts"
            v-model="rule.value"
            outlined
            hide-details
            :items="insurances"
          ></v-select>
          <v-select
            v-else-if="rule.rule == 'risk'"
            label="Kaitse / Risk / Reegel"
            v-model="rule.value"
            outlined
            hide-details
            :items="risks"
          ></v-select>
          <v-select
            v-else-if="rule.rule == 'casco_type'"
            label="Kasko tüüp"
            v-model="rule.value"
            outlined
            hide-details
            :items="casco_type"
          ></v-select>
          <v-select
            v-else-if="rule.rule == 'casco_glass'"
            label="Kasko klaas"
            v-model="rule.value"
            outlined
            hide-details
            :items="casco_glass"
          ></v-select>
          <v-select
            v-else-if="rule.rule == 'territory'"
            label="Kasko tüüp"
            v-model="rule.value"
            outlined
            hide-details
            :items="territory"
          ></v-select>
          <v-text-field
            v-else
            v-model="rule.value"
            label="Value"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="text-right"
        >
          <v-btn
            type="button"
            depressed
            class="mr-2 delete"
            @click="deleteRule"
          >
            <v-icon
              left
              dark
            >
              mdi-close
            </v-icon>
            Kustuta
          </v-btn>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "QueryRule",
    props: ["rule"],
    data() {
      return {
        rule_options: [
          {
            text: 'Selts',
            value: 'insurer'
          },
          {
            text: 'Kaitse / Risk / Reegel',
            value: 'risk'
          },
          {
            text: 'Kehtivuspiirkond',
            value: 'territory'
          },
          {
            text: 'Sõiduki tüüp',
            value: 'vehicle_type'
          },
          {
            text: 'Kasko tüüp',
            value: 'casco_type'
          },
          {
            text: 'Kasko klaas',
            value: 'casco_glass'
          },
          {
            text: 'Pakkumise key',
            value: 'offer_key'
          },
          {
            text: 'Pakkumise key value',
            value: 'offer_key_value'
          },
          {
            text: 'Sõiduki vanus',
            value: 'vehicle_age'
          }
        ],
        risks: [
          {
            text: 'Asendusauto',
            value: 'use_interruption'
          },
          {
            text: 'Kõrgendatud omavastutus väljaspool Eestit toimunud kahju korral',
            value: 'foreign_increased_deductible'
          },
          {
            text: 'Täishävingu omavastutus',
            value: 'total_loss_deductible'
          },
          {
            text: 'Autovahetuskulu',
            value: 'car_exchange_expenses'
          },
          {
            text: 'Välistatud piirkonnad',
            value: 'excluded_regions'
          },
          {
            text: 'Avariijärgne transport',
            value: 'extra_tow_limit'
          },
          {
            text: 'Lisavarustuse hüvitispiir',
            value: 'extra_equipment'
          },
          {
            text: 'Salongi- ja esiklaasi vahetuse omavastutus',
            value: 'glass_deductible'
          },
          {
            text: 'Laadimiskahju hüvitamine',
            value: 'loading_damage'
          },
          {
            text: 'Sõidukiomaniku õigusabikulude kindlustus',
            value: 'lender_placed_insurance'
          },
          {
            text: 'Liisingumakse kindlustus',
            value: 'leasing_payment_protection'
          },
          {
            text: 'Reisijate õnnetusjuhtumikindlustus',
            value: 'accident_coverage'
          },
          {
            text: 'Uusväärtus (ainult uutele sõidukitele)',
            value: 'new_value'
          },
          {
            text: 'Turvapoldid velgedel',
            value: 'sec_bolts'
          },
          {
            text: 'Tehnilise rikke kindlustus',
            value: 'technical_failure'
          },
          {
            text: 'Varguse ja röövi omavastutus',
            value: 'theft_deductible'
          },
          {
            text: 'Omavastutus metsloomaga kokkupõrkel',
            value: 'wild_animal_coverage'
          },
          {
            text: 'Tingimuse link',
            value: 'terms_link'
          },
          {
            text: 'Tingimuse pealkiri',
            value: 'terms_number'
          }
        ],
        casco_type: [
          {
            text: '0',
            value: '0'
          },
          {
            text: '1',
            value: '1'
          },
          {
            text: '2',
            value: '2'
          },
        ],
        casco_glass: [
          {
            text: '0',
            value: '0'
          },
          {
            text: '1',
            value: '1'
          },
          {
            text: '2',
            value: '2'
          },
          {
            text: '3',
            value: '3'
          },
        ],
        territory: [
          {
            text: 'Eesti Vabariik',
            value: 'EST'
          },
          {
            text: 'Euroopa, v.a SRÜ riigid',
            value: 'EU'
          },
          {
            text: 'Euroopa koos SRÜ riigidega',
            value: 'EU_PLUS'
          },
        ],
        insurances: [
          {
            text: 'BTA - 22',
            value: 'BTA'
          },
          {
            text: 'PZU - 23',
            value: 'PZU'
          },
          {
            text: 'LHV - 72',
            value: 'LHV'
          },
          {
            text: 'IF - 2',
            value: 'IfInsurance'
          },
          {
            text: 'Seesam - 9',
            value: 'Seesam'
          },
          {
            text: 'Gjensidige - 20',
            value: 'Gjensidige'
          },
          {
            text: 'Inges - 3',
            value: 'Inges'
          },
          {
            text: 'Ergo - 5',
            value: 'Ergo'
          },
          {
            text: 'Salva - 7',
            value: 'Salva'
          },
        ]
      };
    },
    methods: {
      deleteRule: function() {
        this.$emit('delete_rule')
      }
    }

  }
</script>