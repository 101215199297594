<template>
  <div class="query-block">
    <QueryGroup
      :query="query"
      :lang="lang"
      :delete_btn="false"
    ></QueryGroup>
  </div>
</template>

<script>
  import QueryGroup from './QueryGroup';
  export default {
    name: "QueryBuilder",
    components: { QueryGroup },
    props: ["query", "lang"],
  }
</script>